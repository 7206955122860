import React, { Component } from "react";
import { FacebookProvider, Page } from "react-facebook";
import styled from "styled-components";

export default class Example extends Component {
  render() {
    return (
      <FacebookProvider appId="778257129327064">
        <Desktop>
          <Page
            height="540"
            width="500"
            href="https://www.facebook.com/Green-Park-Katowice-108463137306602/"
            tabs="timeline"
          />
        </Desktop>
        <Mobile className="mobile">
          <Page
            height="350"
            width="300"
            href="https://www.facebook.com/Green-Park-Katowice-108463137306602/"
            tabs="timeline"
          />
        </Mobile>
      </FacebookProvider>
    );
  }
}

const Desktop = styled.div`
  display: none;
  @media only screen and (min-width: 1000px) {
    display: block;
  }
`;

const Mobile = styled.div`
  display: block;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;
