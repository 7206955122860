import React from "react";
import styled from "styled-components";

import Example from "../components/FacebookFeed";
import Img from "gatsby-image";
import { Styledh2 } from "./Area";
import { Styledh3 } from "./Area";

const CommunitySlide = ({ slide }) => {
  return (
    <>
      <StyledWrapper mobile yellow></StyledWrapper>
      <Slide>
        <StyledWrapper desktop yellow></StyledWrapper>
        <StyledImg fluid={slide.imageBg.childImageSharp.fluid}></StyledImg>
        {slide.fb ? (
          <HalfWrapper>
            <Example />
          </HalfWrapper>
        ) : null}

        <AbsoluteWrapper className="dupa">
          <StyledContainer>
            <StyledContentWrapper>
              <Styledh2>{slide.header}</Styledh2>
              <Styledh3>{slide.title}</Styledh3>
              <StyledContent>{slide.content}</StyledContent>
            </StyledContentWrapper>
          </StyledContainer>
        </AbsoluteWrapper>
      </Slide>
    </>
  );
};

export default CommunitySlide;
const StyledContentWrapper = styled.div`
  padding-top: 40px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 1000px) {
    max-width: 430px;
    padding-top: 100px;
  }
`;
const AbsoluteWrapper = styled.div`
  position: absolute;
  top: -340px;
  left: 0;
  right: 0;
  height: 340px;
  h2,
  h3 {
    justify-content: center !important;
  }

  @media only screen and (min-width: 1000px) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: unset;
    text-align: left;
    h2,
    h3 {
      justify-content: flex-start !important;
    }
  }
`;

const Slide = styled.div`
  position: relative;
  height: 350px;
  margin: 0 auto;
  @media only screen and (min-width: 1000px) {
    height: 540px;
  }
`;

const StyledImg = styled(Img)`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  img {
    /* object-fit: contain !important; */
  }
`;

const StyledContent = styled.p`
  font-size: 18px;
  line-height: 1.55;
  text-align: center;
  @media only screen and (min-width: 1000px) {
    text-align: left;
    font-size: 22px;
  }
`;

const StyledWrapper = styled.div`
  height: 340px;
  width: 100%;
  background-color: #ffcd00;
  opacity: 0.8;
  z-index: 1;
  display: ${props => (props.mobile ? "block" : "none")};
  @media only screen and (min-width: 1000px) {
    width: 50%;
    height: 100%;
    display: ${props => (props.mobile ? "none" : "block")};
  }
`;

const HalfWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index : 30;
  background-color : rgba(255,255,255, 0.5);
  /* opacity: ${props => (props.facebook ? 1 : 0)}; */
  transition: 0.4s ease;
  display : flex;
  justify-content : center;
  align-items : center;
  @media only screen and (min-width: 1000px) {
    width: 50%;
  }
`;

export const StyledContainer = styled.div`
  width: 1190px;
  margin: 0 auto;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;

  @media only screen and (max-width: 766.99px) {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
  }

  @media only screen and (max-width: 1023.99px) and (min-width: 768px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media only screen and (max-width: 1365.99px) and (min-width: 1025px) {
    width: 992px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
