import React from "react";
import styled from "styled-components";

import Slider from "react-slick";
import CommunitySlide from "./CommunitySlide";
import ButtonNext from "./Buttonnext";
import ButtonPrev from "./Buttonpre";

const settings = {
  dots: false,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  nextArrow: <ButtonNext to="next" />,
  prevArrow: <ButtonPrev to="prev" />,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        arrows: false,
        infinite: true,
        dots: false
      }
    }
  ]
};

const Community = ({ data }) => {
  return (
    <StyledSection id="community">
      <StyledSlider {...settings}>
        {data.slides.map((slide, index) => {
          return <CommunitySlide key={index} slide={slide}></CommunitySlide>;
        })}
      </StyledSlider>
    </StyledSection>
  );
};

export default Community;

const StyledSlider = styled(Slider)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .slick-dots {
    top: 280px;
    @media only screen and (min-width: 1000px) {
      top: 420px !important;
    }
    @media only screen and (max-width: 1365.99px) and (min-width: 1001px) {
      left: -300px;
    }
    @media only screen and (min-width: 1366px) {
      left: -400px;
    }
  }
`;

const StyledSection = styled.section`
  position: relative;
  height: 680px;
  @media screen and (min-width: 1000px) {
    height: 540px;
  }
`;

export const OvalArrowRight = styled.button`
  position: absolute;
  left: 20px;

  z-index: 2;
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const OvalArrowLeft = styled.button`
  position: absolute;
  right: 20px;

  z-index: 2;
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
